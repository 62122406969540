.nav {
  width: 100%;
  padding: 50px 0;
  z-index: 2;
  text-align: center;
  font-family: var(--sans-serif-font-family, "Segoe UI");
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 100;
  --link-font-color: white; }
  .nav h1 {
    margin-top: 0;
    text-align: center;
    color: var(--yellow, #fece2f);
    letter-spacing: 0.35em; }
  .nav a {
    -webkit-text-decoration-color: transparent;
            text-decoration-color: transparent;
    -webkit-transition: -webkit-text-decoration-color 250ms linear;
    transition: -webkit-text-decoration-color 250ms linear;
    transition: text-decoration-color 250ms linear;
    transition: text-decoration-color 250ms linear, -webkit-text-decoration-color 250ms linear;
    color: var(--link-font-color, white);
    font-family: var(--sans-serif-font-family, "Segoe UI");
    font-weight: 300;
    font-size: 14px;
    margin-right: 12px; }
    .nav a:visited {
      color: var(--link-font-color, white); }
    .nav a:hover {
      -webkit-text-decoration-color: var(--link-font-color, white);
              text-decoration-color: var(--link-font-color, white); }
    .nav a:last-child {
      margin-right: 0; }

@media (max-width: 768px) {
  .nav {
    padding: 35px 0 35px 0; } }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

*[data-font-size-config] {
  font-size: var(--font-size, 1em) !important; }

body, html {
  /* fonts */
  --sans-serif-font-family:  "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  --serif-font-family: "Bodoni MT", Didot, "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif;
  /* colors */
  --yellow: #fece2f;
  --blue: #2965a5;
  --purple: #692bac;
  /* css variables */
  --background-color: white;
  --text-box-background-gradient: rgba(0, 0, 0, 0.3);
  background-color: var(--background-color, #2965a5);
  font-family: var(--serif-font-family, Georgia);
  margin: 0;
  color: black; }

body {
  position: relative; }

/* scaffolding for every page */
.page-container {
  padding-bottom: 50px;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  color: white;
  min-height: 100vh; }
  .page-container .background-gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5); }
  .page-container .page-content {
    z-index: 2;
    position: relative; }
  .page-container.index {
    background-image: url(https://lzilioli.github.io/sous-le-soleil/public/images/featured-3.JPG); }
  .page-container.about {
    background-image: url(https://lzilioli.github.io/sous-le-soleil/public/images/featured-4.JPG); }
  .page-container.packages {
    background-image: url(https://lzilioli.github.io/sous-le-soleil/public/images/featured-11.JPG); }
  .page-container.package {
    background-image: url(https://lzilioli.github.io/sous-le-soleil/public/images/featured-12.JPG); }

/*******************************************************************************
/ Image Carousel
/*******************************************************************************/
.image-carousel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow: hidden; }
  .image-carousel img {
    height: 200px;
    border: 1px solid #fff; }
  .image-carousel.direction-left img {
    -webkit-animation: bannermove-left 50s linear infinite;
            animation: bannermove-left 50s linear infinite; }
  .image-carousel.direction-right img {
    -webkit-animation: bannermove-right 50s linear infinite;
            animation: bannermove-right 50s linear infinite; }

@-webkit-keyframes bannermove-left {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(var(--totalImageWidthNegative), 0, 0);
            transform: translate3d(var(--totalImageWidthNegative), 0, 0); } }

@keyframes bannermove-left {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(var(--totalImageWidthNegative), 0, 0);
            transform: translate3d(var(--totalImageWidthNegative), 0, 0); } }

@-webkit-keyframes bannermove-right {
  0% {
    -webkit-transform: translate3d(var(--totalImageWidthNegative), 0, 0);
            transform: translate3d(var(--totalImageWidthNegative), 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bannermove-right {
  0% {
    -webkit-transform: translate3d(var(--totalImageWidthNegative), 0, 0);
            transform: translate3d(var(--totalImageWidthNegative), 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

/*******************************************************************************
/ Index Page Stuff
/*******************************************************************************/
.blurb-white {
  font-family: var(--sans-serif-font-family, "Segoe UI"); }

.blurb-yellow {
  margin: 25px 0;
  color: var(--yellow, #fece2f);
  font-family: var(--sans-serif-font-family, "Segoe UI");
  font-weight: 300;
  font-size: 22px; }
  .blurb-yellow a {
    color: white; }
    .blurb-yellow a:hover {
      color: white; }
    .blurb-yellow a:visited {
      color: white; }

.center {
  text-align: center; }

.justify {
  text-align: justify; }

/*******************************************************************************
/ About Page
/*******************************************************************************/
.about-page-container {
  width: 90%;
  max-width: 700px;
  margin: 0 auto; }

/*******************************************************************************
/ Package Page
/*******************************************************************************/
.package-page {
  width: 100%;
  position: relative; }

.package-page-content {
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
  color: white;
  z-index: 2;
  position: relative; }

.packages-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly; }

/*
Renders as a box on the top of the parent div.
Usage:
 	.booked {
		left: 0px;
		border-right: 1px solid var(--yellow, #fece2f);
		@extend %header-callout;
	}
*/
.package-container .booked, .package-container .contact {
  position: absolute;
  top: 0;
  color: white;
  border-bottom: 1px solid var(--yellow, #fece2f);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 7px 12px; }
  .package-container .booked a, .package-container .contact a {
    color: white;
    font-weight: bold; }
    .package-container .booked a:visited, .package-container .contact a:visited {
      color: white; }

.package-container {
  margin: 20px auto;
  border: 1px solid white;
  width: 100%;
  padding: 20px;
  background-color: var(--text-box-background-gradient, rgba(0, 0, 0, 0.3));
  position: relative; }
  .package-container .booked {
    left: 0px;
    border-right: 1px solid var(--yellow, #fece2f); }
  .package-container .contact {
    right: 0px;
    border-left: 1px solid var(--yellow, #fece2f); }

.package-name {
  text-align: center;
  color: var(--yellow, #fece2f);
  font-size: 22px; }
  .package-name .small-underline {
    margin-top: 12px; }

.package-price {
  margin: 12px auto;
  font-size: 18px;
  line-height: 1.25;
  text-align: center; }
  .package-price .small-underline {
    margin-bottom: 12px; }

.package-page-link, .button-link {
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  font-size: 18px;
  color: var(--yellow, #fece2f);
  font-weight: bold;
  text-decoration: none;
  padding: 10px 20px;
  border: 1px solid var(--yellow, #fece2f);
  color: white;
  -webkit-transition: color 250ms ease-out, background-color 250ms ease-out;
  transition: color 250ms ease-out, background-color 250ms ease-out; }
  .package-page-link:visited, .button-link:visited {
    color: var(--yellow, #fece2f); }
  @media (hover: hover) {
    .package-page-link:hover, .button-link:hover {
      background-color: var(--yellow, #fece2f);
      color: black; } }

.package-page-link {
  max-width: 150px;
  display: block; }

.date-range {
  color: white;
  font-size: 0.75em; }

.small-underline {
  display: block;
  width: 78px;
  margin: 0 auto;
  height: 1px;
  background-color: var(--yellow, #fece2f); }

/*******************************************************************************
/ Footer stuff
/*******************************************************************************/
.footer {
  font-family: var(--sans-serif-font-family, "Segoe UI");
  margin: 30px auto;
  text-align: center;
  color: var(--white, #ffffff);
  padding: 10px; }
  .footer a {
    font-size: 27px;
    font-weight: 200;
    padding-bottom: 50px; }
    .footer a, .footer a:visited, .footer a:hover, .footer a:active {
      color: var(--yellow, #fece2f); }

@media (max-width: 768px) {
  .topper-content {
    width: 90%;
    margin-top: 20px; }
  .packages-container .package-container {
    margin: 20px auto; } }

.contains-markdown * {
  font-family: var(--sans-serif-font-family, "Segoe UI"); }

.contains-markdown h1, .contains-markdown h2, .contains-markdown h3, .contains-markdown h4, .contains-markdown h5, .contains-markdown h6 {
  text-align: center;
  text-transform: uppercase;
  font-family: var(--serif-font-family, Georgia); }

/*******************************************************************************
// Package Page
/*******************************************************************************/
